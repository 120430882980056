/* Deprecated Grid CSS */
.ui-grid {
	clear: both;
	padding: 0;
	margin: 0;
}

.ui-grid::before,
.ui-grid::after {
	content:"";
	display:table;
}

.ui-grid::after {
	clear:both;
}

.ui-grid .ui-grid-row {
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	clear:both;
}

.ui-grid-row::after {
	clear: both;
	content: "";
	display: table;
}

.ui-grid-col-1,
.ui-grid-col-2,
.ui-grid-col-3,
.ui-grid-col-4,
.ui-grid-col-5,
.ui-grid-col-6,
.ui-grid-col-7,
.ui-grid-col-8,
.ui-grid-col-9,
.ui-grid-col-10,
.ui-grid-col-11,
.ui-grid-col-12 {
	float: left;
	box-sizing: border-box;
}

.ui-grid-col-1 {
	width: 8.33333%;
}

.ui-grid-col-2 {
	width: 16.66666%;
}

.ui-grid-col-3 {
	width: 25%;
}

.ui-grid-col-4 {
	width: 33.33333%;
}

.ui-grid-col-5 {
	width: 41.66666%;
}

.ui-grid-col-6 {
	width: 50%;
}

.ui-grid-col-7 {
	width: 58.33333%;
}

.ui-grid-col-8 {
	width: 66.66666%;
}

.ui-grid-col-9 {
	width: 75%;
}

.ui-grid-col-10 {
	width: 83.33333%;
}

.ui-grid-col-11 {
	width: 91.66666%;
}

.ui-grid-col-12 {
	width: 100%;
}

@media (min-width: 480px) {
	.ui-grid-fixed {
		width: 480px;
	}
}

@media (min-width: 768px) {
	.ui-grid-fixed {
		width: 768px;
	}
}

@media (min-width: 960px) {
	.ui-grid-fixed {
		width: 960px;
	}
}

@media (min-width: 1024px) {
	.ui-grid-fixed {
		width: 1024px;
	}
}

/* Responsive */
@media (max-width: 640px) {
	.ui-grid-responsive .ui-grid-row {
		display: block;
	}

	.ui-grid-responsive .ui-grid-col-1,
	.ui-grid-responsive .ui-grid-col-2,
	.ui-grid-responsive .ui-grid-col-3,
	.ui-grid-responsive .ui-grid-col-4,
	.ui-grid-responsive .ui-grid-col-5,
	.ui-grid-responsive .ui-grid-col-6,
	.ui-grid-responsive .ui-grid-col-7,
	.ui-grid-responsive .ui-grid-col-8,
	.ui-grid-responsive .ui-grid-col-9,
	.ui-grid-responsive .ui-grid-col-10,
	.ui-grid-responsive .ui-grid-col-11,
	.ui-grid-responsive .ui-grid-col-12 {
		width: 100%;
		float: none;
	}
}

.ui-grid.ui-grid-pad > .ui-grid-row > div {
	padding: .25em .5em;
}

/* Responsive */
@media (max-width: 640px) {
	.ui-grid-responsive .ui-grid-row {
		display: block;
	}

	.ui-grid-responsive .ui-grid-col-1,
	.ui-grid-responsive .ui-grid-col-2,
	.ui-grid-responsive .ui-grid-col-3,
	.ui-grid-responsive .ui-grid-col-4,
	.ui-grid-responsive .ui-grid-col-5,
	.ui-grid-responsive .ui-grid-col-6,
	.ui-grid-responsive .ui-grid-col-7,
	.ui-grid-responsive .ui-grid-col-8,
	.ui-grid-responsive .ui-grid-col-9,
	.ui-grid-responsive .ui-grid-col-10,
	.ui-grid-responsive .ui-grid-col-11,
	.ui-grid-responsive .ui-grid-col-12 {
		width: 100%;
		float: none;
	}
}

/* New Grid CSS */
.ui-g {
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	flex-wrap: wrap;
	box-sizing: border-box;
}

.ui-g::after {
	clear: both;
	content: "";
	display: table;
	box-sizing: border-box;
}

.ui-g-1,
.ui-g-2,
.ui-g-3,
.ui-g-4,
.ui-g-5,
.ui-g-6,
.ui-g-7,
.ui-g-8,
.ui-g-9,
.ui-g-10,
.ui-g-11,
.ui-g-12 {
	float: left;
	box-sizing: border-box;
	padding: 0.5em;
}

.ui-g-1 {
	width: 8.3333%;
}

.ui-g-2 {
	width: 16.6667%;
}

.ui-g-3 {
	width: 25%;
}

.ui-g-4 {
	width: 33.3333%;
}

.ui-g-5 {
	width: 41.6667%;
}

.ui-g-6 {
	width: 50%;
}

.ui-g-7 {
	width: 58.3333%;
}

.ui-g-8 {
	width: 66.6667%;
}

.ui-g-9 {
	width: 75%;
}

.ui-g-10 {
	width: 83.3333%;
}

.ui-g-11 {
	width: 91.6667%;
}

.ui-g-12 {
	width: 100%;
}

.ui-g-offset-12 {
	margin-left: 100%;
}

.ui-g-offset-11 {
	margin-left: 91.66666667%;
}

.ui-g-offset-10 {
	margin-left: 83.33333333%;
}

.ui-g-offset-9 {
	margin-left: 75%;
}

.ui-g-offset-8 {
	margin-left: 66.66666667%;
}

.ui-g-offset-7 {
	margin-left: 58.33333333%;
}

.ui-g-offset-6 {
	margin-left: 50%;
}

.ui-g-offset-5 {
	margin-left: 41.66666667%;
}

.ui-g-offset-4 {
	margin-left: 33.33333333%;
}

.ui-g-offset-3 {
	margin-left: 25%;
}

.ui-g-offset-2 {
	margin-left: 16.66666667%;
}

.ui-g-offset-1 {
	margin-left: 8.33333333%;
}

.ui-g-offset-0 {
	margin-left: 0%;
}

@media screen and (max-width: 40em) {
	.ui-sm-1,
	.ui-sm-2,
	.ui-sm-3,
	.ui-sm-4,
	.ui-sm-5,
	.ui-sm-6,
	.ui-sm-7,
	.ui-sm-8,
	.ui-sm-9,
	.ui-sm-10,
	.ui-sm-11,
	.ui-sm-12 {
		padding: 0.5em;
	}

	.ui-sm-1 {
		width: 8.3333%;
	}

	.ui-sm-2 {
		width: 16.6667%;
	}

	.ui-sm-3 {
		width: 25%;
	}

	.ui-sm-4 {
		width: 33.3333%;
	}

	.ui-sm-5 {
		width: 41.6667%;
	}

	.ui-sm-6 {
		width: 50%;
	}

	.ui-sm-7 {
		width: 58.3333%;
	}

	.ui-sm-8 {
		width: 66.6667%;
	}

	.ui-sm-9 {
		width: 75%;
	}

	.ui-sm-10 {
		width: 83.3333%;
	}

	.ui-sm-11 {
		width: 91.6667%;
	}

	.ui-sm-12 {
		width: 100%;
	}

	.ui-sm-offset-12 {
		margin-left: 100%;
	}

	.ui-sm-offset-11 {
		margin-left: 91.66666667%;
	}

	.ui-sm-offset-10 {
		margin-left: 83.33333333%;
	}

	.ui-sm-offset-9 {
		margin-left: 75%;
	}

	.ui-sm-offset-8 {
		margin-left: 66.66666667%;
	}

	.ui-sm-offset-7 {
		margin-left: 58.33333333%;
	}

	.ui-sm-offset-6 {
		margin-left: 50%;
	}

	.ui-sm-offset-5 {
		margin-left: 41.66666667%;
	}

	.ui-sm-offset-4 {
		margin-left: 33.33333333%;
	}

	.ui-sm-offset-3 {
		margin-left: 25%;
	}

	.ui-sm-offset-2 {
		margin-left: 16.66666667%;
	}

	.ui-sm-offset-1 {
		margin-left: 8.33333333%;
	}

	.ui-sm-offset-0 {
		margin-left: 0%;
	}
}

@media screen and (min-width: 40.063em) {
	.ui-md-1,
	.ui-md-2,
	.ui-md-3,
	.ui-md-4,
	.ui-md-5,
	.ui-md-6,
	.ui-md-7,
	.ui-md-8,
	.ui-md-9,
	.ui-md-10,
	.ui-md-11,
	.ui-md-12 {
		padding: 0.5em;
	}

	.ui-md-1 {
		width: 8.3333%;
	}

	.ui-md-2 {
		width: 16.6667%;
	}

	.ui-md-3 {
		width: 25%;
	}

	.ui-md-4 {
		width: 33.3333%;
	}

	.ui-md-5 {
		width: 41.6667%;
	}

	.ui-md-6 {
		width: 50%;
	}

	.ui-md-7 {
		width: 58.3333%;
	}

	.ui-md-8 {
		width: 66.6667%;
	}

	.ui-md-9 {
		width: 75%;
	}

	.ui-md-10 {
		width: 83.3333%;
	}

	.ui-md-11 {
		width: 91.6667%;
	}

	.ui-md-12 {
		width: 100%;
	}

	.ui-md-offset-12 {
		margin-left: 100%;
	}

	.ui-md-offset-11 {
		margin-left: 91.66666667%;
	}

	.ui-md-offset-10 {
		margin-left: 83.33333333%;
	}

	.ui-md-offset-9 {
		margin-left: 75%;
	}

	.ui-md-offset-8 {
		margin-left: 66.66666667%;
	}

	.ui-md-offset-7 {
		margin-left: 58.33333333%;
	}

	.ui-md-offset-6 {
		margin-left: 50%;
	}

	.ui-md-offset-5 {
		margin-left: 41.66666667%;
	}

	.ui-md-offset-4 {
		margin-left: 33.33333333%;
	}

	.ui-md-offset-3 {
		margin-left: 25%;
	}

	.ui-md-offset-2 {
		margin-left: 16.66666667%;
	}

	.ui-md-offset-1 {
		margin-left: 8.33333333%;
	}

	.ui-md-offset-0 {
		margin-left: 0%;
	}
}

@media screen and (min-width: 64.063em) {
	.ui-lg-1,
	.ui-lg-2,
	.ui-lg-3,
	.ui-lg-4,
	.ui-lg-5,
	.ui-lg-6,
	.ui-lg-7,
	.ui-lg-8,
	.ui-lg-9,
	.ui-lg-10,
	.ui-lg-11,
	.ui-lg-12 {
		padding: 0.5em;
	}

	.ui-lg-1 {
		width: 8.3333%;
	}

	.ui-lg-2 {
		width: 16.6667%;
	}

	.ui-lg-3 {
		width: 25%;
	}

	.ui-lg-4 {
		width: 33.3333%;
	}

	.ui-lg-5 {
		width: 41.6667%;
	}

	.ui-lg-6 {
		width: 50%;
	}

	.ui-lg-7 {
		width: 58.3333%;
	}

	.ui-lg-8 {
		width: 66.6667%;
	}

	.ui-lg-9 {
		width: 75%;
	}

	.ui-lg-10 {
		width: 83.3333%;
	}

	.ui-lg-11 {
		width: 91.6667%;
	}

	.ui-lg-12 {
		width: 100%;
	}

	.ui-lg-offset-12 {
		margin-left: 100%;
	}

	.ui-lg-offset-11 {
		margin-left: 91.66666667%;
	}

	.ui-lg-offset-10 {
		margin-left: 83.33333333%;
	}

	.ui-lg-offset-9 {
		margin-left: 75%;
	}

	.ui-lg-offset-8 {
		margin-left: 66.66666667%;
	}

	.ui-lg-offset-7 {
		margin-left: 58.33333333%;
	}

	.ui-lg-offset-6 {
		margin-left: 50%;
	}

	.ui-lg-offset-5 {
		margin-left: 41.66666667%;
	}

	.ui-lg-offset-4 {
		margin-left: 33.33333333%;
	}

	.ui-lg-offset-3 {
		margin-left: 25%;
	}

	.ui-lg-offset-2 {
		margin-left: 16.66666667%;
	}

	.ui-lg-offset-1 {
		margin-left: 8.33333333%;
	}

	.ui-lg-offset-0 {
		margin-left: 0%;
	}
}

@media screen and (min-width: 90.063em) {
	.ui-xl-1,
	.ui-xl-2,
	.ui-xl-3,
	.ui-xl-4,
	.ui-xl-5,
	.ui-xl-6,
	.ui-xl-7,
	.ui-xl-8,
	.ui-xl-9,
	.ui-xl-10,
	.ui-xl-11,
	.ui-xl-12 {
		padding: 0.5em;
	}

	.ui-xl-1 {
		width: 8.3333%;
	}

	.ui-xl-2 {
		width: 16.6667%;
	}

	.ui-xl-3 {
		width: 25%;
	}

	.ui-xl-4 {
		width: 33.3333%;
	}

	.ui-xl-5 {
		width: 41.6667%;
	}

	.ui-xl-6 {
		width: 50%;
	}

	.ui-xl-7 {
		width: 58.3333%;
	}

	.ui-xl-8 {
		width: 66.6667%;
	}

	.ui-xl-9 {
		width: 75%;
	}

	.ui-xl-10 {
		width: 83.3333%;
	}

	.ui-xl-11 {
		width: 91.6667%;
	}

	.ui-xl-12 {
		width: 100%;
	}

	.ui-xl-offset-12 {
		margin-left: 100%;
	}

	.ui-xl-offset-11 {
		margin-left: 91.66666667%;
	}

	.ui-xl-offset-10 {
		margin-left: 83.33333333%;
	}

	.ui-xl-offset-9 {
		margin-left: 75%;
	}

	.ui-xl-offset-8 {
		margin-left: 66.66666667%;
	}

	.ui-xl-offset-7 {
		margin-left: 58.33333333%;
	}

	.ui-xl-offset-6 {
		margin-left: 50%;
	}

	.ui-xl-offset-5 {
		margin-left: 41.66666667%;
	}

	.ui-xl-offset-4 {
		margin-left: 33.33333333%;
	}

	.ui-xl-offset-3 {
		margin-left: 25%;
	}

	.ui-xl-offset-2 {
		margin-left: 16.66666667%;
	}

	.ui-xl-offset-1 {
		margin-left: 8.33333333%;
	}

	.ui-xl-offset-0 {
		margin-left: 0%;
	}
}

.ui-g-nopad {
	padding: 0;
}

