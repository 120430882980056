.p-inputtext {
	width: 100%;
	font-size: 0.8rem;
}
.p-fieldset .p-fieldset-legend{
	padding: 0.5rem;
	color: #00095b;
	background: #FFFFFF;;
	font-weight: 700;
	font-size: 13px;
	text-decoration: none;
	border: 1px solid #FFFFFF
}
.p-breadcrumb ul li.p-breadcrumb-chevron {
	margin: 0 0.5rem 0 0.5rem;
	color: #215a8f;
}
.p-breadcrumb ul li .p-menuitem-link .p-menuitem-text{
	color: #215a8f;
	margin: 0;
}

.breadcrumb-wrapper .p-menuitem-text , .breadcrumb-wrapper .p-menuitem-separator{
	color: #00095b !important;
}

.p-breadcrumb {
	background: #FAFAFA;
	color: #00095b;
	border: 1px solid #dee2e6;
	border-radius: 3px;
	padding: 0.5rem;
}



.p-datatable .p-datatable-thead > tr > th {
	text-align: center;
	padding: 0.571em 0.35em;
	border: 0.7px solid #A7A7A7 !important;
	font-weight: 600;
	font-size: 13px;
	color: #fff;
	background: #00095b;
	transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
}

.p-datatable .p-datatable-tbody > tr > td {
	font-size: 13px;
	border: 1px solid rgba(0, 0, 0, 0.08);
	padding: 0.571em 0.35em;
	border: 1px solid #A7A7A7;
}

.p-tabview > .p-tabview-panels {
	padding: 0 !important;
}

.p-fieldset .p-fieldset-content{
	padding: 0.1rem;
}
.p-button {
	color: #fff;
	background: #00095b;
	border: 0.5px solid #00095b;
	padding: 0.4rem 0.4rem;
	font-size: 13px;
	transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
	border-radius: 3px;
}
.p-menubar {
	padding: 0.1rem;
	background: #00095b;
	color: #4d4d4d;
	border: 1px solid #dee2e6;
	border-radius: 3px;
}
.p-menubar .p-submenu-list{
	z-index: 100 !important;
}

footer, #app-footer {
	background-color: #fff;
	flex: 0 0 auto;
	padding: 0.25em 1em;
	text-align: center;
	width: 100%;
	margin-top: 0px;
}
.p-component {
	font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
	font-size: 15px;
	font-weight: normal;
	margin-top: 3px;
}
body {
	background-color: #FFFFFF;

}
.p-fieldset {
	border-radius: 3px;
	border: 1px solid #A7A7A7;
	//background-color: #FAFAFA;
	color: #333333;
}

#ui-button-success {
	color: #fff;
	background-color: #b85500;
	border: 1px solid #c88310
}
#ui-button-submit {
	background-color: #0d5407;
	color: #FFFFFF;
	border-color: #15830B;
}
.p-tabview.p-tabview-top .p-tabview-nav li {
	float: none;
	display: inline-block;
	color: #24292F;
	padding: 0.571em 1em;
	font-weight: 400;
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link {
	border: solid #dee2e6;
	border-width: 1px 1px 2px 1px;
	border-color: #24292F #24292F #24292F #24292F;
	background: #fff;
	 color: #24292F;
	padding: 0.571em 1em;
	font-weight: 400;
	border-top-right-radius: 3px;
	border-top-left-radius: 3px;
	transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
	margin: 1px 1px -2px 1px;
}
.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
	background: #00095b;
	border-color: #fff;
	color: #fff;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
	color: #fff;
	background: #2861a4;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {

	margin: 0;
	padding: 0.429em 0.857em;
	border: 0 none;
	color: #333333;
	background-color: transparent;
	border-radius: 0;
}

 .p-dropdown-item {
	 font-weight: normal;
	 border: 0 none;
	 cursor: pointer;
	 margin: 1px 0;
	 padding: 0.125em 0.25em;
	 text-align: left;
 }
.p-dropdown .p-dropdown-label {
	display: block;
	border: 0;
	white-space: nowrap;
	overflow: hidden;
	font-weight: normal;
	width: 100%;
	//padding-right: 2.0em;
}

.attachment-cursor {
	cursor: pointer;
	font-size: 1em;
	padding-bottom: 50px;
	font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
}
body .p-dropdown .p-dropdown-clear-icon {
	color: #555;
}

.p-dropdown .p-dropdown-clear-icon {
	position: absolute;
	right: 2em;
	top: 50%;
	font-size: 1em;
	height: 1em;
	margin-top: -0.5em;
}
.p-inline-message .p-inline-message-text {
	font-size: 0.8rem;
}

i{
	margin-right: 8px;
}